export default function AppBar() {
    return (
        <div className="appBar">
            <div>
            {/*<a href="/">*/}
                <img src="../logo192.png" onClick={(e) => { window.location.assign('/') }}/>
                <p onClick={(e) => { window.location.assign('/') }}>Instinct</p>
            {/*</a>*/}
                <ul className="nav">
                    <li><a href="/" className="enabled-link">Home</a></li>
                    <li><a href="/projects/" className="enabled-link">Projects</a></li>
                    <li><a href="#" className="disabled-link" onClick={(e) => {e.preventDefault() } }>Developer</a></li>
                    
                </ul>
            </div>
        </div>
    );
}