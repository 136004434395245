import logo from './logo.svg';
import './styles/App.css';

import AppBar from './shared/appBar';
import * as nav from'./js/nav.js'

function App() {
  return (
      <div className="App grid">
          <AppBar />
          <div className="container grid-color">
              <div className="body-content">
                  <div className="intro intro-bg">
                      <div className="intro-content">
                          <div>
                              <img src="logo192.png" />
                          </div>
                          <div>
                              <h1>Welcome!</h1>
                              <h3>Here us be</h3>
                          </div>
                      </div>
                  </div>
                  <div className="content">
                      <div className="content-select">
                          <ul className="nav">
                              <li onClick={() => nav.changeContent('projects')}>Projects</li>
                              <li onClick={() => nav.changeContent('about')}>About</li>
                          </ul>
                      </div>
                      <div id="about" className="remaining" style={{ display: 'none' }}>
                          <div className="filler"></div>
                      </div>
                      <div id="projects" className="remaining">
                          <div className="content-box">
                              <img src="logo192.png" />
                              <div className="content-text">
                                  <h3>FluxFyre</h3>
                                  <p>
                                      ChatGPT Chat <br />
                                      A ChatGPT wrapper using a custom server. <br />
                                      Router with config for custom saved prompt and behaviour building for multiple endpoints. *API
                                  </p>
                                  <div className="content-button-container">
                                      <div className="content-button"><a href="http://fluxfyre.instinct-universe.com/">Web Demo</a></div>
                                      <div className="content-button disabled" onClick={(e) => { e.preventDefault() }}><a href="">More Info (make a page)</a></div>
                                  </div>
                              </div>
                          </div>
                          {/*<div className="content-box">*/}
                          {/*    <img src="FloWiseAI_dark.png" />*/}
                          {/*    <div className="content-text">*/}
                          {/*        <h3>Flowise</h3>*/}
                          {/*        <p>*/}
                          {/*            Chat / Assistant builder. <br />*/}
                          {/*            UI to build AI flows using a modular node system*/}
                          {/*        </p>*/}
                          {/*        <div className="content-button-container">*/}
                          {/*            <div className="content-button"><a href="https://apps.instinct-universe.com">Enter</a></div>*/}
                          {/*            <div className="content-button"><a href="http://flowiseai.com">Official Site</a></div>*/}
                          {/*        </div>*/}
                          {/*    </div>*/}
                          {/*</div>*/}
                          
                          <div className="content-box">
                              <img src="48276018.png" />
                              <div className="content-text">

                                  <h3>Miro App - AI Integration</h3>
                                  <p>
                                      Miro: A team, canvas for organizing projects.<br />
                                      Flowise AI flow connected with Miro App to provide summaries and answer questions about content.
                                      (Store link & demo pending)
                                  </p>
                                  <div className="content-button-container">
                                      <div className="content-button disabled" onClick={(e) => { e.preventDefault() }}><a href="https://instinct-universe.com">Miro Store</a></div>
                                      <div className="content-button"><a href="https://miro.com">Official Site</a></div>
                                  </div>
                              </div>
                              
                          </div>
                          <div className="content-box">
                              <img src="U_Cube_1C_White.png" />
                              <div className="content-text">
                                  <h3>StormFyre</h3>
                                  <p>
                                      AI NPCs in a virtual village using my custom AI solution (FluxFyre).<br />
                                      Web, PC, and VR. (Coming Soon)
                                  </p>
                                  <div className="content-button-container">
                                      <div className="content-button disabled" onClick={(e) => { e.preventDefault() }}><a href="https://instinct-universe.com">Enter</a></div>
                                      <div className="content-button disabled" onClick={(e) => { e.preventDefault() }}><a href="http://instinct-universe.com">Official Site</a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  
              </div>
              <div className="footer">
                  <div className="content">
                      <p className="content-text">Pie in the sky with low hanging fruit</p>
                      <img src="logo192.png" />
                  </div>
              </div>
          </div>
    </div>
  );
}

export default App;
