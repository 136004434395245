import $ from 'jquery';

export function changeContent(text) {
    if (text.toLowerCase() === "about") {
        $('#about')[0].style.display = 'block';
        $('#projects')[0].style.display = 'none';
    } else if (text.toLowerCase() === "projects") {
        $('#about')[0].style.display = 'none';
        $('#projects')[0].style.display = 'block';
    }

}