import AppBar from '../shared/appBar';
import Footer from '../shared/Footer.js';
import '../styles/App.css';
import '../styles/projects.css';

export default function Contact() {
    return (
        <div className="App grid">
            <AppBar />
            <div className="container grid-color">
                <div className="body-content">
                    <div className="intro-medium intro-medium-bg">
                        <div className="intro-content">
                            <div>
                                <img src="../../logo192.png" />
                            </div>
                            <div>
                                <h1>Projects</h1>
                                <h3>Here us be</h3>
                            </div>
                            <div>use hammer screwdriver image & computer</div>
                        </div>
                    </div>
                    <div className="content">
                        <div id="projects" className="remaining">
                            <div className="content-box">
                                <div className="content-image proj">
                                    <img src="../../logo192.png" />
                                    <div className="content-button-container proj">
                                        <div className="content-button"><a href="http://apps.instinct-universe.com/fluxfyre">Web Demo</a></div>
                                        <div className="content-button"><a href="http://flowiseai.com">Web Demo</a></div>
                                    </div>
                                </div>
                                
                                <div className="content-text">
                                    <h3>FluxFyre</h3>
                                    <p>
                                        FluxFyre is a platform that adds modular features on top of OpenAI's ChatGPT.
                                        With this API, Json configs provide persistent user-defined prompts and customizeable behaviour.
                                        FluxFyre supports routing between multiple chat endpoints, each behaving according to thier config.
                                        Changes can be made at runtime, providing real-time bot management.
                                        <br />I created this to support <a href="">StormFyre</a>, providing multiple NPCs each with a configurable pre-defined "role" that they play.
                                        <ul>Features:
                                            <li>Multi-destination</li>
                                            <li>Modular dynamic JSON config</li>
                                            <li>API Access</li>
                                            <li>Session caching</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="content-box">
                                <div className="content-image proj">
                                    <img src="../../U_Cube_1C_White.png" />
                                    <div className="content-button-container proj">
                                        <div className="content-button disabled"><a href="" onClick={(e) => { e.preventDefault()} }>Web Demo</a></div>
                                        <div className="content-button"><a href="http://flowiseai.com">More Info</a></div>
                                    </div>
                                </div>

                                <div className="content-text">
                                    <h3>StormFyre (In Progress)</h3>
                                    <p>
                                        Unity powered Virtual world filled with AI controlled NPCs. 
                                        <a href=""> FluxFyre</a> provides a platform for creating and managing these bots at runtime.
                                        <ul>Features:
                                            <li>Multiple AI NPCs</li>
                                            <li>Unique, manageable behaviour</li>
                                            <li>PC, Web, VR</li>
                                            <li>Ready Player Me Avatar Support</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="content-box">
                                <div className="content-image proj">
                                    <img src="../../48276018.png" />
                                    <div className="content-button-container proj">
                                        <div className="content-button"><a href="http://fluxfyre.instinct-universe.com/">Web Demo</a></div>
                                        <div className="content-button"><a href="http://flowiseai.com">More Info</a></div>
                                    </div>
                                </div>

                                <div className="content-text">
                                    <h3>Miro App (Name)</h3>
                                    <p>
                                        Miro: A team, canvas for organizing projects.<br />
                                        Flowise AI flow connected with Miro App to provide summaries and answer questions about content.
                                        (Store link & demo pending)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    );
}